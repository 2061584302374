/* eslint-disable*/
export const orderTableCols = [
  {
    prop: 'orderCode',
    label: '订单号',
    fixed: 'left',
    width: 150
  },
  {
    prop: 'totalPrice',
    label: '订单总额',
    fixed: 'left',
    slotName: 'totalPriceSlot'
  },
  {
    prop: 'productCustomSkuCode',
    label: 'SKU',
    slotName: 'productCustomSkuCodeSlot',
    width: 150
  },
  { prop: 'productStructName', label: '款式', slotName: 'productStructNameSlot' },
  { prop: 'productSizeName', label: '尺码', slotName: 'productSizeNameSlot' },
  {
    prop: 'lowerLimitPrice',
    label: '最低售价',
    slotName: 'lowerLimitPriceSlot'
  },
  { prop: 'internalUnitPrice', label: '单价', slotName: 'internalUnitPriceSlot' },
  { prop: 'realUnitPrice', label: '实际单价', slotName: 'realUnitPriceSlot' ,width: 150},
  { prop: 'productCount', label: '数量', slotName: 'productCountSlot' },
  { prop: 'couponAmountSun', label: '单品优惠', slotName: 'couponAmountSunSlot', width: 150 },
  { prop: 'SKUTotalPrice', label: 'SKU总价', slotName: 'SKUTotalPriceSlot' },
  { prop: 'expressCompanyName', label: '物流方式', slotName: 'expressCompanySlot' },
  { prop: 'templateFreight', label: '运费', slotName: 'templateFreightSlot' },
  { prop: 'couponAmount', label: '优惠金额', slotName: 'couponAmountSlot', fixed: 'right' }
]
export const productTableCols = [
  { prop: 'productCnName', label: '产品名称' },
  { prop: 'productStructName', label: '款式' },
  { prop: 'productCount', label: '数量' },
  { prop: 'internalUnitPrice', label: '单价' },
  { prop: 'internalTotalPrice', label: '总金额' }
]
